.password-icon-button {
  position: absolute;
  border: 0;
  margin-left: -30px;
  margin-top: 17px;
  background-color: transparent;
}

.error-field-msg {
  color: #ff3333;
  font-size: 13px;
  font-style: italic;
  font-weight: 500;
}

.error-field-msg svg {
  color: #ff3333;
}

.pwd-strength-tip {
  margin-top: 0.5em;
  font-weight: bold;
}

.level {
  width: 100%;
  height: 10px;
}

.level-weak {
  width: 12.5%;
  height: 10px;
  display: inline-block;
  background: #8a0303;
}

.level-good {
  width: 25%;
  height: 10px;
  display: inline-block;
  background: #cc0000;
}

.level-average {
  width: 37.5%;
  height: 10px;
  display: inline-block;
  background: orange;
}

.level-strong {
  width: 50%;
  height: 10px;
  display: inline-block;
  background: #68ca6e;
}

@media (max-width: 764px) {
  .level-weak {
    width: 25%;
  }

  .level-good {
    width: 50%;
  }

  .level-average {
    width: 75%;
  }

  .level-strong {
    width: 100%;
  }
}






















/* CSS ESPECIFICOS DO MODULO EMPRESAS
*/

.breadcrumb {
  flex-grow: 0;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.svg-inline--fa.fa-w-18 {
  width: 1em;
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.avatar-pic{
  height: 27px;
  cursor: pointer;
}
.link-contraste{
  cursor: pointer;
}

.menu {
  max-width: 200px;
  display: none;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 2px 4px #00000033;
  margin: 0px;
  padding: 0px;
  position: absolute ;
  right: calc(5vw + 157px);
  margin-top: 26px;
}

button + .menu:active,
button:focus + .menu {
  display: inline;
}

.btn-menu{
  width: 24px;
  height: 24px;
  text-align: center;
  font: Solid 16px/20px Font Awesome 5 Free;
  letter-spacing: 0;
  color: #1351B4;
  opacity: 1;
  background-color: white;
  border: 0px;
}

.btn-menu:hover, .btn-menu:focus {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #DBE8FB 0% 0% no-repeat padding-box;
  opacity: 1;
}

.btn-menu-item{
  cursor: pointer;
  padding: 1em;
  text-align: left;
  font: 14px/18px Rawline;
  letter-spacing: 0;
  color: #333333;
  opacity: 1;
  border: 0px;
  background-color: white;
  width: 100%;
  margin: 0px;
}
.btn-menu-item:hover {
  background: #DBE8FB 0% 0% no-repeat padding-box;
  color: #071d41;
}

header{
  background-color: white;
}
#root{
  background: #F8F8F8 0% 0% no-repeat padding-box;
}
main{
  background: #F8F8F8 0% 0% no-repeat padding-box;
}
.action-group{
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-top: 1px solid #CCCCCC;
  padding: 0.5em 1em;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 3em auto auto;
}

.action-group-left{
  padding: 0;
  text-align: left;
  flex: 1 0;
}

.step-group{
  width: 100%;
  padding: 1em 1em;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  display: flex;
  flex-wrap: wrap;
  opacity: 1;
}

.step-title-selected{
  text-align: center;
  font: Bold 20px/28px Rawline;
  letter-spacing: 0;
  color: #0C326F;
  opacity: 1;
  flex: 0 0 33%;
}

.step-title-not-selected{
  text-align: center;
  font: 20px/28px Rawline;
  letter-spacing: 0;
  color: #1351B4;
  opacity: 1;
  flex: 0 0 33%;
}

.fa-trash{
  text-align: center;
  font: Solid 16px/20px Font Awesome 5 Free;
  letter-spacing: 0;
  color: #1351B4;
  opacity: 1;
}

.step-title-passed{
  text-align: center;
  font: 20px/28px Rawline;
  letter-spacing: 0;
  color: #0C326F;
  opacity: 1;
  flex: 0 0 33%;
}

.step-selected{
  color: white;
  font-family: 'rawline';
  font: Bold 24px/33px Rawline;
  text-align: center;
  vertical-align: middle;
  position: relative;
  padding: 6px;
  background: #0C326F;
  opacity: 1;
  margin: auto;
  letter-spacing: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}


.step-passed{
  color: white;
  font-family: 'rawline';
  font: Bold 24px/33px Rawline;
  text-align: center;
  vertical-align: middle;
  position: relative;
  padding: 6px;
  background: #C5D4EB 0% 0% no-repeat padding-box;
  border: 2px solid #C5D4EB;
  opacity: 1;
  margin: auto;
  letter-spacing: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}


.step-not-selected{
  color: #1351B4;
  font: Bold 24px/33px Rawline;
  
  text-align: center;
  vertical-align: middle;
  position: relative;
  padding: 4px;
  background: #FEFEFE 0% 0% no-repeat padding-box;
  border: 2px solid #1351B4;
  border-radius: 50%;
  opacity: 1;
  margin: auto;
  letter-spacing: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card{
  padding-top: 2em;
  text-align: center;
  width: 45%;
  padding-bottom: 0px;
}
.card span{
  width: 100%;
  padding: 0px;
}
.card img{
  height: 10%;
  text-align: center;
}

.card p{
  text-align: center;
  flex: 1 0 100%;
}

.card a{
  text-align: center;
  flex: 1 0 50%;
}

.card input[type='text']{
  width: 100%;
}

@media (max-width: 1366px) {
  .card{
    width: 50%;
  }
}
@media (max-width: 1024px) {
  .card{
    width: 65%;
  }
}
@media (max-width: 764px) {
  .card{
    width: 80%;
  }
}
@media (max-width: 500px) {
  .card{
    width: 100%;
  }
  .card img{
    height: 7%;
    text-align: center;
  }
}


.container-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}

.modal{
  top: 2em;
  font: 20px/28px Rawline;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  text-align: center;
  padding: 1em 0;
}

.modal img{
  max-height: 3.5em;
  max-width: 3.5em;
  margin-right: 1em;
}
.modal p{
  font-size: medium;
  font-weight: 300;
  margin: 0px;
  padding: 0 2em;
  text-align: center;
}
.modal div{
  margin: 1em 0px;
  padding: 1em 2em;
  background-color: #F8F8F8;
}

.modal div p{
  font-size: medium;
  font-weight: 300;
  text-align: left;
}
.modal footer{
  width: 100%;
  text-align: right;
  padding: 1em 1em 0 1em;
}
.modal qrcode{
  margin: 1em 0;
}
.modal ul{
  font-size: medium;
  font-weight: 300;  
  list-style-type: none;
  text-align: left;
}
.modal li{
  font-size: medium;
  font-weight: 300;
  list-style-type: none;
  text-align: left;
}
.modal table{
  background-color: #F8F8F8;
  font-size: medium;
  margin-top: 2em;
}

@media (max-width: 764px) {
  .modal table td{
    text-align: center;
    width:100%;
  }
  .modal p{
    font-size: small;
  }
  .modal div{
    font-size: small;
  }
  .modal div p{
    font-size: small;
  }
  .modal div li{
    font-size: small;
  }
  footer {
    display: block;
    text-align: center;
    margin: 0px;
    padding: 0px;
  }
  button{
    background-color: #777;
  }
}

.panel-reliability-card-inactive {
  display: flex;
  width: 100%;
  flex-flow: wrap;
}


.reliability-card-inactive{
  
  margin-right: 1em;
  margin-bottom: 1em;
  min-height: 110px;
  max-width: 32%;
  padding: 1em;
  box-shadow: 0 2px 4px #00000033;
  text-align: center;
  
  
}

.reliability-card-inactive:hover{
  cursor: pointer;
  background-color: #f8f8f8;
  color: #1351B4;
  
}
.reliability-card-inactive:hover .acquiredCategory{
  
  border-color: #ecebeb;
}
.reliability-card-inactive:hover img{
  
  opacity: 1;
}

.reliability-card-inactive img {
  max-height: 3em;
  max-width: 3em;
  margin: 0.5em 1em 0 1em;
  opacity: 0.7;
}


.reliability-card-inactive p {
  margin: 0px;
  font-size: small;
  margin-bottom: 1em;
  
}

.reliability-card-inactive h4 {
  margin: 0px;
  text-transform: uppercase;
}

.acquiredCategory{
  border-width: 2px;
  border-style: solid;
  border-color: #F8F8F8;
  padding: 0 0.5em;
  margin: 0.5em 0;
}


fieldset{
  padding: 1em;
  width: 100%;
  height: auto;
  margin: 1em 0;
}

fieldset{
  border-width: 2px;
  border-style: solid;
  border-color: #F8F8F8;
  padding: 0em 1em;
}


.reliability-card{
    margin: 1em 0;
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 2px 4px #00000033;
    text-align: left;
    width: 99%;
}
.reliability-card p {
  padding: 0em 1em 0.5em 1.4em;
  margin: 0px;
  font-size: small;
  margin-bottom: 1em;
}

h3{
  text-transform: uppercase;
}
.reliability-card h4 {
  padding: 1em 1em 0 1em;
  margin: 0px;
  text-transform: uppercase;
}


.reliability-card img {
  cursor: pointer;
}

.reliability-card span {
  cursor: pointer;
  position: relative;
  left: 1em;
  bottom: 1.0em;
  
}

.reliability-item{
  display: flex;
  padding: 0.5em 1.0em;
}
.reliability-item:hover{
  cursor: pointer;
  background-color: #F8F8F8;
  color: #1351B4;
  border-radius: 4px;
}

.reliability-item img{
  max-height: 2.5em;
  max-width: 2.5em;
}

.reliability-item p{
  flex: 1 0 30%;
  font-size: medium;
  margin-top: 0.6em;
}


table img{
  max-height: 2em;
  max-width: 2em;
  margin: 0px;
  padding: 0px;
}

.table-not-bordered {
    border: 0;
    border-collapse: inherit;
    margin-top: 2em;
    width: 100%;
    padding: 0em;
}
.table-not-bordered td{
    margin: 1em;
    padding: 0.2em;
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 2px 4px #00000033;
    
}
.table-not-bordered table td input[type='text']{
    width: 100%;
}

select{
    width: 100%;
    border: 1px solid #888888;
    padding: 0.5em;
    background-color: #efefef;
    border-radius: 6px;
    height: 40px;
    padding: 8px 16px;
    margin: 5px 0 0 0;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Rawline';
}



table th input[type='text']{
    border-color: #ccc;
}

button {
    margin: 0 1em;
}


















