.container-cadastro {
  display: flex;
  flex-wrap: wrap;
}

.container-foto {
  flex: 1 0 50%;
}

.container-senha {
  flex: 1 0 50%;
}
